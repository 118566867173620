import request from '@/utils/request'


// 查询服务订单请求列表
export function listServeProvider(query) {
  return request({
    url: '/order/order-serve-provider/list',
    method: 'get',
    params: query
  })
}

// 查询服务订单请求分页
export function pageServeProvider(query) {
  return request({
    url: '/order/order-serve-provider/page',
    method: 'get',
    params: query
  })
}

// 查询服务订单请求详细
export function getServeProvider(data) {
  return request({
    url: '/order/order-serve-provider/detail',
    method: 'get',
    params: data
  })
}

// 新增服务订单请求
export function addServeProvider(data) {
  return request({
    url: '/order/order-serve-provider/add',
    method: 'post',
    data: data
  })
}

// 修改服务订单请求
export function updateServeProvider(data) {
  return request({
    url: '/order/order-serve-provider/edit',
    method: 'post',
    data: data
  })
}

// 删除服务订单请求
export function delServeProvider(data) {
  return request({
    url: '/order/order-serve-provider/delete',
    method: 'post',
    data: data
  })
}

//确认报价
export function enterBaojia(data) {
  return request({
    url: '/order/order-serve-provider/confirm',
    method: 'post',
    data
  })
}