<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <!-- <b>{{ formTitle }}</b> -->
      <b>{{ formTitle == '添加' ? $t('通用.新增') : $t('通用.修改') }} </b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.服务者id')" prop="userWaiterId">
        <a-input v-model="form.userWaiterId" :placeholder="$t('服务管理.服务订单请求管理.请输入服务者id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.用户项目id')" prop="userProjectId">
        <a-input v-model="form.userProjectId" :placeholder="$t('服务管理.服务订单请求管理.请输入用户项目id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.订单id')" prop="orderServiceId">
        <a-input v-model="form.orderServiceId" :placeholder="$t('服务管理.服务订单请求管理.请输入订单id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.服务状态')" prop="status">
        <a-select
          v-model="form.status"
          show-search
          :placeholder="$t('服务管理.服务订单请求管理.请选择服务状态')"
          style="width: 100%"
        >
          <!-- ,0-未开始,1-待开始/待上传,2-待支付,3-已支付 -->
          <a-select-option value="0"> 未开始 </a-select-option>
          <a-select-option value="1"> 待开始 </a-select-option>
          <a-select-option value="2"> 待支付 </a-select-option>
          <a-select-option value="3"> 已支付 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.服务签约定金')" prop="deposit">
        <a-input v-model="form.deposit" :placeholder="$t('服务管理.服务订单请求管理.请输入服务签约定金')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.报价方案')" prop="quotationScheme">
        <a-input
          v-model="form.quotationScheme"
          :placeholder="$t('服务管理.服务订单请求管理.请输入内容')"
          type="textarea"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务订单请求管理.备注')" prop="remark">
        <a-input
          v-model="form.remark"
          :placeholder="$t('服务管理.服务订单请求管理.请输入内容')"
          type="textarea"
          allow-clear
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> {{ $t('通用.保存') }} </a-button>
          <a-button type="dashed" @click="cancel"> {{ $t('通用.取消') }} </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getServeProvider, addServeProvider, updateServeProvider } from '@/api/order/serveProvider'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userWaiterId: null,

        userProjectId: null,

        orderServiceId: null,

        status: 0,

        deposit: null,

        quotationScheme: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userWaiterId: [{ required: true, message: '服务者id,外键{zb_user_waiter.id}不能为空', trigger: 'blur' }],
        userProjectId: [{ required: true, message: '用户项目id,外键{zb_user_project}不能为空', trigger: 'blur' }],
        orderServiceId: [{ required: true, message: '订单id,外键{zb_order_service.id}不能为空', trigger: 'blur' }],
        status: [
          {
            required: true,
            message: '服务状态,0-待接单,1-待平台确认,2-待报价,3-待确认,4-制作中,5-已完成,6-已拒绝不能为空',
            trigger: 'blur',
          },
        ],
        deposit: [{ required: true, message: '服务签约定金不能为空', trigger: 'blur' }],
        quotationScheme: [
          { required: true, message: '报价方案,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userWaiterId: null,
        userProjectId: null,
        orderServiceId: null,
        status: 0,
        deposit: null,
        quotationScheme: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getServeProvider({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateServeProvider(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addServeProvider(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
