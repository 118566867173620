<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="服务者id,外键{zb_user_waiter.id}" prop="userWaiterId">-->
            <!--                <a-input v-model="queryParam.userWaiterId" placeholder="请输入服务者id,外键{zb_user_waiter.id}" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.服务订单请求管理.状态')" prop="singleStatus">
                <a-select v-model="queryParam.singleStatus" placeholder="请选择状态">
                  <a-select-option :value="0">待接单</a-select-option>
                  <a-select-option :value="1">待平台确认</a-select-option>
                  <a-select-option :value="2">待报价</a-select-option>
                  <a-select-option :value="3">待确认</a-select-option>
                  <a-select-option :value="4">制作中</a-select-option>
                  <a-select-option :value="5">已完成</a-select-option>
                  <a-select-option :value="6">已拒绝</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="订单id" prop="orderServiceId">
                  <a-input v-model="queryParam.orderServiceId" placeholder="请输入订单id" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="服务签约定金" prop="deposit">
                  <a-input v-model="queryParam.deposit" placeholder="请输入服务签约定金" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" /> {{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
                <!--                  {{ advanced ? '收起' : '展开' }}-->
                <!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:serve/provider:add']">
          <a-icon type="plus" />{{ $t('通用.新增') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['order:serve/provider:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['order:serve/provider:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['order:serve/provider:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:serve/provider:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:serve/provider:edit']">
            <a-icon type="edit" />{{ $t('通用.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['order:serve/provider:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['order:serve/provider:remove']">
            <a-icon type="delete" />{{ $t('通用.删除') }}
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['order:serve/provider:remove']"
            v-show="record.quotationScheme !== ''"
          />
          <a
            @click="$refs.watchreport.handleUpdate(record)"
            v-hasPermi="['order:serve/provider:remove']"
            v-show="record.quotationScheme !== ''"
          >
            <a-icon type="search" />{{ $t('服务管理.服务订单请求管理.查看方案') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['order:serve/provider:remove']" v-show="record.status == 1" />
          <a-popconfirm
            title="确认报价?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="handleEnter(record)"
            v-show="record.status == 1"
          >
            <a v-hasPermi="['order:serve/provider:edit']"> <a-icon type="enter" />{{ $t('通用.确认') }} </a>
          </a-popconfirm>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!-- 查看方案弹框 -->
    <watch-report ref="watchreport" />
  </page-header-wrapper>
</template>

<script>
import { pageServeProvider, listServeProvider, delServeProvider, enterBaojia } from '@/api/order/serveProvider'
import CreateForm from './modules/CreateForm'
import WatchReport from './modules/watch-report.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ServeProvider',
  components: {
    CreateForm,
    WatchReport,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userWaiterId: null,
        userProjectId: null,
        orderServiceId: null,
        status: null,
        singleStatus: null,
        deposit: null,
        quotationScheme: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '主键',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '服务者姓名',
          dataIndex: 'userWaiterName',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '用户项目id,外键{zb_user_project}',
        //   dataIndex: 'userProjectId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '订单id,外键{zb_order_service.id}',
        //   dataIndex: 'orderServiceId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '服务状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待接单'
            } else if (text == 1) {
              return '待平台确认'
            } else if (text == 2) {
              return '待报价'
            } else if (text == 3) {
              return '待确认'
            } else if (text == 4) {
              return '制作中'
            } else if (text == 5) {
              return '已完成'
            } else {
              return '已拒绝'
            }
          },
        },
        {
          title: '服务签约定金',
          dataIndex: 'deposit',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '项目名称',
          dataIndex: 'projectName',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '报价方案,外键{zb_file.id},多个使用逗号隔开',
        //   dataIndex: 'quotationScheme',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '请求时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()

  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询服务订单请求列表 */
    getList() {
      this.loading = true
      pageServeProvider(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userWaiterId: undefined,
        userProjectId: undefined,
        orderServiceId: undefined,
        status: undefined,
        singleStatus: undefined,
        deposit: undefined,
        quotationScheme: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content: this.$t('服务管理.服务内容管理.当前选中编号为') + ids,
        okText: this.$t('通用.确认'),
        cancelText: this.$t('通用.取消'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delServeProvider(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'order/order-serve-provider/export',
            {
              ...that.queryParam,
            },
            `服务订单请求_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },

    //确认报价
    handleEnter(row) {
      enterBaojia({
        id: row.id,
      }).then((res) => {
        // console.log(res)
        if (res.message == '成功.') {
          this.getList()
        }
      })
    },
    //查看方案
    handleWatch(row) {},
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'ID',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Service name',
            dataIndex: 'userWaiterName',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'Service status',
            dataIndex: 'status',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待接单'
              } else if (text == 1) {
                return '待平台确认'
              } else if (text == 2) {
                return '待报价'
              } else if (text == 3) {
                return '待确认'
              } else if (text == 4) {
                return '制作中'
              } else if (text == 5) {
                return '已完成'
              } else {
                return '已拒绝'
              }
            },
          },
          {
            title: 'deposit',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'project Name',
            dataIndex: 'projectName',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'CreateTime',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'Operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '主键',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '服务者姓名',
            dataIndex: 'userWaiterName',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '服务状态',
            dataIndex: 'status',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待接单'
              } else if (text == 1) {
                return '待平台确认'
              } else if (text == 2) {
                return '待报价'
              } else if (text == 3) {
                return '待确认'
              } else if (text == 4) {
                return '制作中'
              } else if (text == 5) {
                return '已完成'
              } else {
                return '已拒绝'
              }
            },
          },
          {
            title: '服务签约定金',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '项目名称',
            dataIndex: 'projectName',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '请求时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },

          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
